<template>
  <div id="app">
    <b-card
      style="max-width: 32rem"
      class="mx-auto"
      header-text-variant="white"
      header-class="header"
      header-html="<b><i>Personel Yönetim Sistemi</i></b>"
    >
      <b-form @submit="onSubmit">
        <InputTile
          label="Yeni Şifre"
          type="password"
          v-model="form.newPassword"
          required
        />
        <InputTile
          label="Yeni Şifre (Tekrar)"
          type="password"
          v-model="form.repeatPassword"
          required
        />
        <b-form-row class="center spacing">
          <b-col>
            <b-button type="submit" variant="success">Gönder</b-button>
          </b-col>
        </b-form-row>
        <b-select v-show="false" v-model="getNotification"></b-select>
      </b-form>
    </b-card>
  </div>
</template>
<script>
import InputTile from "@/components/forms/common/InputTile.vue";
import { mapGetters } from "vuex";
export default {
  name: "ResetPassword",
  components: { InputTile },
  data() {
    return {
      form: {
        newPassword: "",
        repeatPassword: "",
      },
    };
  },
  computed: {
    ...mapGetters("notification", ["getError", "getNotifications"]),
    getNotification: {
      get() {
        this.getNotifications.forEach((e) => {
          this.$bvToast.toast(e.body, {
            title: e.title,
            variant: e.type,
            solid: true,
          });
        });
        this.$store.dispatch("notification/setNotifications", []);
        return this.getNotifications;
      },
      set() {
        return this.getNotifications;
      },
    },
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      if (this.form.newPassword === this.form.repeatPassword) {
        this.$store.dispatch("changePassword", this.form).then(() => {
          this.$router.push("/");
        });
      } else {
        this.$store.dispatch("notification/setNotifications", [
          {
            title: "Hata!",
            body: "Girilen şifreler uyuşmuyor!",
            type: "danger",
          },
        ]);
      }
    },
  },
};
</script>


<style scoped>
html,
body {
  background-color: black;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 10rem;
  height: 100%;
  background-color: #222222;
  color: #333333;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.header {
  background: rgb(5, 114, 184);
  background: linear-gradient(
    0deg,
    rgba(5, 114, 184, 1) 40%,
    rgba(17, 150, 236, 1) 85%
  );
}
.labelContainer {
  display: grid;
  place-items: center;
}
.center {
  text-align: center;
}
.spacing {
  margin-top: 0.5rem;
}
</style>